<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl';
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import MessageMenu from './MessageMenu'
import Repository from "@/app/repository/repository-factory";
import Vue from "vue";
import Swal from "sweetalert2";

const MessageRepository = Repository.get("MessageRepository");

export default {
  components: {Layout, PageHeader, DatePicker, MessageMenu},
  data() {
    return {
      title: 'Wiadomości',
      items: [
        {
          text: 'Wiadomości',
          href: '/',
        },
        {
          text: 'Skrzynka',
          active: true,
        },
      ],
      filters: {
        search: '',
        dateFrom: null,
        dateTo: null,
      },
      isBusy: false,
      excelInProgress: false,
      unreadMessages: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: "",
      filterOn: [''],
      sortBy: 'date',
      sortDesc: true,
      fields: [
        {key: "title", label: "Tytuł", sortable: false, tdClass : 'text-left', thClass : 'text-left', enabledIn: ['inbox', 'unread', 'outbox', 'draft']},
        {key: "sendAt", label: "Data wysłania", sortable: false, tdClass: 'text-right', thClass : 'text-right', enabledIn: ['outbox']},
        {key: "changedAt", label: "Data aktualizacji", sortable: false, tdClass : 'text-right', thClass : 'text-right', enabledIn: ['inbox', 'unread']},
        {key: "action", label: "Akcje", sortable: false, tdClass: 'text-right', thClass : 'text-right', enabledIn: ['draft']}
      ]
    }
  },
  computed: {
    computedFields() {
      if (this.messageCategory() === 'inbox') {
        return this.fields.filter(field => field.enabledIn.includes('inbox'));
      }

      if (this.messageCategory() === 'unread') {
        return this.fields.filter(field => field.enabledIn.includes('unread'));
      }

      if (this.messageCategory() === 'outbox') {
        return this.fields.filter(field => field.enabledIn.includes('outbox'));
      }

      if (this.messageCategory() === 'draft') {
        return this.fields.filter(field => field.enabledIn.includes('draft'));
      }

      return this.fields;
    }
  },
  watch: {
    '$route'() {
      this.$refs['message-list'].refresh();
    },
    filters: {
      handler: function () {
        this.currentPage = 1;
        this.$refs['message-list'].refresh();
      },
      deep: true
    },
  },
  methods: {
    messageCategory() {
      return this.$route.params.messageCategory;
    },
    removeDraft(uuid, version) {
      this.inProgress = true;
      Swal.fire({
        title: "Usuwanie wiadomości",
        text: "Usuniętej wiadomości nie będzie można przywrócić. Czy na pewno powinna być usunięta?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Tak, usuń wiadomość!",
        cancelButtonText: "Anuluj"
      }).then(result => {
        if (result.value) {
          MessageRepository.removeDraft(uuid, version).then(() => {
            Swal.fire("Usunięto wiadomość!", "Wiadomość została poprawnie usunięta.", "success");
            this.$refs['message-list'].refresh()
            this.inProgress = false;
          }).catch(error => {
            Vue.swal({
              icon: "error",
              position: 'top',
              title: "Wystąpił błąd. Spróbuj jeszcze raz!",
              text: error.response.data.message,
              showConfirmButton: true
            });
          })
        }
      });
      this.inProgress = false;
    },
    findUnreadMessages() {
      const promise = MessageRepository.findAllUnreadMessagesIds();

      return promise.then((data) => {
        this.unreadMessages = data.data.messagesId;
      }).catch(error => {
        console.log(error);
        return []
      })
    },
    async axiosProvider(ctx) {
      await this.findUnreadMessages();

      const search = (this.filters.search !== null) ? this.filters.search : '';
      const dateFrom = (this.filters.dateFrom !== null) ? this.filters.dateFrom : '';
      const dateTo = (this.filters.dateTo !== null) ? this.filters.dateTo : '';
      const parameters = '?page=' + ctx.currentPage + '&maxItems=' + ctx.perPage +
        '&messageCategory=' + this.messageCategory() +
        '&search=' + search +
        '&dateFrom=' + dateFrom +
        '&dateTo=' + dateTo;

      let payload = {
        "unreadMessages": [],
      };

      this.unreadMessages.forEach(function (item) {
        payload.unreadMessages.push({"messageId": item});
      });

      const promise = MessageRepository.getGrid(parameters, payload);

      return promise.then((data) => {

        this.totalRows = data.data.info.allItems;

        return data.data.requestData || []
      }).catch(error => {
        console.log(error);
        return []
      })
    }
    ,
  }
  ,
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <!-- Right Sidebar -->
      <div class="col-12">
        <!-- Left sidebar -->
        <div class="email-leftbar card">
          <MessageMenu/>
        </div>
        <!-- End Left sidebar -->

        <div class="email-rightbar mb-3">
          <div class="card">
            <div class="card-body bg">

              <div class="row mb-2">
                <div class="col-sm-12 col-lg-12">
                  <b-form inline>
                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                    <div class="ml-2 search-box">
                      <div class="position-relative">
                        <b-form-input v-model="filters.search" :debounce="450" placeholder="Szukaj..."></b-form-input>
                        <i class="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </b-form>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-sm-3 col-lg-2">
                  <date-picker
                    id="dateFrom"
                    name="dateFrom"
                    v-model="filters.dateFrom"
                    :first-day-of-week="1"
                    value-type="format"
                    placeholder="od"
                    lang="pl">
                  </date-picker>
                </div>
                <div class="col-sm-3 col-lg-2">
                  <date-picker
                    id="dateTo"
                    name="dateTo"
                    v-model="filters.dateTo"
                    :first-day-of-week="1"
                    value-type="format"
                    placeholder="do"
                    lang="pl">
                  </date-picker>
                </div>
              </div>

              <div class="mt-3">
                <div class="table-responsive mb-0">
                  <b-table
                    ref="message-list"
                    :items="axiosProvider"
                    :fields="computedFields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn">

                    <template v-slot:table-busy>
                      <div class="text-center text-black">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Ładowanie danych...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(title)="row">
                      <span
                        :class="[unreadMessages.includes(row.item.uuid) ? 'font-weight-bold': 'font-weight-normal']">
                        <b-link v-if="row.item.status === 'draft'" :to="{ name: 'Edycja wiadomości', params: { id: row.item.uuid } }">
                          <span class="text-body">{{ row.item.title }}</span>
                        </b-link>
                        <b-link v-else :to="{ name: 'Szczegóły wiadomości', params: { id: row.item.uuid } }">
                          <span class="text-body">{{ row.item.title }}</span>
                        </b-link>
                        <span class="text-primary" v-if="row.item.countReplies > 0">
                          <b-badge :title="'Ilość odpowiedzi ' + row.item.countReplies" variant="light"
                                   class="position-absolute" style="margin-left: 10px; margin-top: 2px;">
                          {{ row.item.countReplies }}</b-badge>
                        </span>
                      </span>
                    </template>

                    <template v-slot:cell(sendAt)="row">
                      <span class="font-size-12 font-weight-light" :row="row.item.uuid">{{ row.item.sendAt.substring(0,16) }}</span>
                    </template>

                    <template v-slot:cell(changedAt)="row">
                      <span class="font-size-12 font-weight-light" :row="row.item.uuid">{{ row.item.changedAt.substring(0,16) }}</span>
                    </template>

                    <template v-slot:cell(action)="row">
                      <ul class="list-inline font-size-16 contact-links mb-0 text-nowrap">
                        <li class="list-inline-item px-0">
                          <a v-b-tooltip.hover title="Edycja wiadomości">
                            <b-link :to="{ name: 'Edycja wiadomości', params: { id: row.item.uuid } }">
                              <i class="bx bx-edit"></i></b-link>
                          </a>
                        </li>
                        <li class="list-inline-item px-0">

                        <span v-on:click="removeDraft(row.item.uuid, row.item.version)"
                              v-b-tooltip.hover title="Usuwanie wiadomości">
                            <a href="#"><i class="bx bx-trash"></i></a>
                        </span>

                        </li>
                      </ul>
                    </template>
                  </b-table>
                </div>

              </div>
              <div class="row">
                <div class="col">
                  <template v-if="totalRows === 0">
                    <div class="d-flex justify-content-center mt-3 font-weight-light small">
                      <b>Nie znaleziono wiadomości</b>
                    </div>
                  </template>
                  <template v-if="totalRows > 0">
                    <div class="dataTables_paginate paging_simple_numbers float-left ml-2 mt-2 font-weight-lighter small">
                      Znaleziono {{ totalRows }} wiadomości.
                    </div>
                  </template>
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="totalRows"
                                    :per-page="perPage"
                                    first-number last-number></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
